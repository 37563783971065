import { useCallback, useEffect, useState } from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import axios from "axios";

import { config } from "../../config";
import { FrontValidationError } from "../../errors";
import { CsvParser } from "../../components/CsvParser";
import { OutputModal } from "../../components/OutputModal";
import { categoryCsvToApi, productCsvToApi } from "../../csv/mappers";
import { beautifyErrors } from "../../csv/errorMappers";

const JOB_POLLING_DELAY = 30000; // ms
export const CatalogUpload = ({ token }: { token: string }) => {
  const toast = useToast();
  const [categoryCsvRows, setCategoryCsvRows] = useState<any>();
  const [productCsvRows, setProductCsvRows] = useState<any>();
  const [errors, setErrors] = useState<any>([]);
  const [warnings, setWarnings] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [jobId, setJobId] = useState();
  const [isPolling, setIsPolling] = useState(false);

  const pollJobStatus = useCallback(async () => {
    const response = await axios.get(
      `${config.environment.API_URL}/jobStatus/${jobId}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    if (response.data.status !== "done") return false;
    if (response.data.missingStoreKeys.length === 0) {
      toast({
        title: "Catalog successfully uploaded to Uber Eats!",
      });
      setWarnings(response.data.warnings || []);
    } else {
      setWarnings([
        ...response.data.missingStoreKeys.map(
          (store: string) =>
            `Synchronisation failed for the following store: ${store}`,
        ),
        ...(response.data.warnings || []),
      ]);
    }
    return true;
  }, [token, jobId, toast]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const categoryCsvData = categoryCsvToApi(categoryCsvRows);
      const productCsvData = productCsvToApi(productCsvRows);

      setWarnings([]);

      const response = await axios.post(
        `${config.environment.API_URL}/catalog`,
        {
          ...categoryCsvData,
          ...productCsvData,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      setErrors([]);
      toast({
        title:
          "Your catalog is currently uploading, this operation will take around 6 minutes, please stay on the page...",
      });
      setJobId(response.data.jobId);
    } catch (error: any) {
      if (error instanceof FrontValidationError) {
        setErrors([error.message]);
        return;
      }

      if (!Array.isArray(error.response?.data?.message)) {
        setErrors(["Something went wrong, please try again"]);
        return;
      }

      setErrors(beautifyErrors(error.response.data.message));
      setIsLoading(false);
    }
  }, [categoryCsvRows, productCsvRows, token, toast]);

  useEffect(() => {
    if (jobId && !isPolling) {
      // Poll API for catalog upload status every 5 seconds
      let isDone = false;
      const interval = setInterval(async () => {
        try {
          isDone = await pollJobStatus();
        } catch (error) {
          setErrors(["Something went wrong, please try again"]);
          isDone = true;
        }
        if (isDone) {
          setJobId(undefined);
          setIsLoading(false);
          setIsPolling(false);
          clearInterval(interval);
        }
      }, JOB_POLLING_DELAY);
      setIsPolling(true);
    }
  }, [jobId, pollJobStatus, isPolling]);

  useEffect(() => {
    if (errors?.length) {
      setErrorModalIsOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    if (warnings?.length) {
      setWarningModalIsOpen(true);
    }
  }, [warnings]);

  return (
    <>
      <Box p={5}>
        <CsvParser
          title="Category & Subcategory CSV"
          onFileChange={(rows: any) => {
            setCategoryCsvRows(rows);
          }}
        />
        <Box mt={5} />
        <CsvParser
          title="Product & Pricing CSV"
          onFileChange={(rows: any) => {
            setProductCsvRows(rows);
          }}
        />

        <Button
          rightIcon={<FiArrowRight />}
          onClick={onSubmit}
          disabled={!categoryCsvRows || !productCsvRows}
          mt={5}
          isLoading={isLoading}
        >
          Upload catalog to Uber Eats
        </Button>
      </Box>
      <OutputModal
        title="CSV NOT UPLOADED - You need to fix the problems below"
        isOpen={errorModalIsOpen}
        onClose={() => setErrorModalIsOpen(false)}
        output={errors}
      />
      <OutputModal
        title="CSV upload success - Check the following warnings"
        isOpen={warningModalIsOpen}
        onClose={() => setWarningModalIsOpen(false)}
        output={warnings}
      />
    </>
  );
};
