import { Category, Product } from "./types";
import { FrontValidationError } from "../errors";

const parseNumber = (nbr: string) =>
  parseFloat(
    nbr
      .replace(",", ".")
      .replace(/[^0-9.]/g, "")
      .trim(),
  );

const categoryRowToApi = (row: any, index: number): Category => {
  if (!["category", "subcategory"].includes(row.type)) {
    throw new FrontValidationError(
      'Category type must be "category" or "subcategory" (case sensitive)',
    );
  }

  return {
    csv_index: index,
    type: row.type?.trim(),
    name: row.name?.trim() || "",
    handle: row.handle?.trim() || "",
    parent_category_handle: row.parent_category_handle?.trim() || "",
    category_order: row.category_order
      ? parseInt(row.category_order.trim())
      : undefined,
    subcategory_order: row.subcategory_order
      ? parseInt(row.subcategory_order.trim())
      : undefined,
    is_displayed: row.is_displayed
      ? row.is_displayed?.trim() === "TRUE"
      : undefined,
  };
};

export const categoryCsvToApi = (
  rows: any[],
): {
  categories: Category[];
  subcategories: Category[];
} => {
  const formattedRows = rows.map(categoryRowToApi);
  const categories = formattedRows.filter(
    (row: any) => row.type === "category",
  );
  const subcategories = formattedRows.filter(
    (row: any) => row.type === "subcategory",
  );

  return {
    categories,
    subcategories,
  };
};

const productRowToApi = (row: any, index: number): Product => {
  return {
    csv_index: index,
    sku: row.sku?.trim(),
    name: row.name?.trim(),
    ean: row.ean?.trim(),
    description: row.description?.trim(),
    keywords: row.keywords?.trim(),
    picture_url: row.picture_url?.trim(),
    vat_rate: row.vat_rate ? parseNumber(row.vat_rate) : undefined,
    weight: row.weight ? parseFloat(row.weight.trim()) : undefined,
    is_alcohol: row.is_alcohol?.trim() === "TRUE",
    is_bio: row.is_bio?.trim() === "TRUE",
    pvc: row.pvc ? parseNumber(row.pvc) : undefined,
    subcategory_handle: row.subcategory_handle?.trim(),
    order: row.order ? parseFloat(row.order.trim()) : undefined,
    department_code: row.department_code
      ? parseNumber(row.department_code)
      : undefined,
  };
};

export const productCsvToApi = (
  rows: any[],
): {
  products: Product[];
} => {
  return {
    products: rows.map(productRowToApi),
  };
};
