import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  output: string[];
};

export const OutputModal = ({ title, output, isOpen, onClose }: Props) => {
  const outputAsText = useMemo(
    () => output.map((line) => `${line}\n`).join(""),
    [output]
  );
  const onDownloadAsText = useCallback(() => {
    const element = document.createElement("a");
    const file = new Blob([outputAsText], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "output.txt";
    document.body.appendChild(element);
    element.click();
  }, [outputAsText]);

  const onCopyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(outputAsText);
  }, [outputAsText]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List>
            {output.map((line, index) => (
              <ListItem key={index}>{line}</ListItem>
            ))}
          </List>
        </ModalBody>

        <ModalFooter>
          <Button mr={4} colorScheme="blue" onClick={onDownloadAsText}>
            Download
          </Button>
          <Button colorScheme="blue" onClick={onCopyToClipboard}>
            Copy to clipboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
