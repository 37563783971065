import { Button, IconButton, InputGroup } from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { FiFile, FiX } from "react-icons/fi";

type FileUploadProps = {
  accept?: string;
  onChange?: (file: File) => void;
  onClear?: () => void;
};

export const FileInput: FC<FileUploadProps> = ({
  accept,
  onChange,
  onClear,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => inputRef.current?.click();
  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.[0]) return;

    const file = e.target.files[0];
    setFile(file);
    e.target.files && onChange?.(file);
  };
  const onClearFile = () => {
    setFile(undefined);
    if (inputRef.current) inputRef.current.value = "";
    onClear?.();
  };

  return (
    <InputGroup>
      <input
        type={"file"}
        multiple={false}
        hidden
        accept={accept}
        ref={inputRef}
        onChange={onFileChange}
      />
      {
        <Button
          onClick={handleClick}
          rightIcon={<FiFile />}
          colorScheme={file ? "teal" : undefined}
        >
          {file?.name || "Upload file"}
        </Button>
      }
      {file && (
        <IconButton
          ml={2}
          aria-label="clear"
          icon={<FiX />}
          onClick={onClearFile}
        />
      )}
    </InputGroup>
  );
};
