import { Box, Heading } from "@chakra-ui/react";
import { useCallback } from "react";
import Papa from "papaparse";
import { FileInput } from "./FileInput";

type Props = {
  title: string;
  onFileChange: (t: any) => void;
};

export const CsvParser = ({ title, onFileChange }: Props) => {
  const onChange = useCallback(
    (file: File) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => onFileChange(results.data),
      });
    },
    [onFileChange],
  );

  const onClear = useCallback(() => {
    onFileChange(undefined);
  }, [onFileChange]);

  return (
    <Box>
      <Heading size="lg" mb={2}>
        {title}
      </Heading>

      <FileInput onChange={onChange} accept=".csv" onClear={onClear} />
    </Box>
  );
};
