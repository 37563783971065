import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import axios from "axios";
import { config } from "../../config";

type Props = {
  onLogin: (token: string) => void;
};

export const Login = ({ onLogin }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);

      try {
        const response = await axios.post(
          `${config.environment.API_URL}/login`,
          {
            email,
            password,
          }
        );

        setError("");
        onLogin(response.data.token);
      } catch (e: any) {
        if ([400, 401].includes(e.response?.status)) {
          setError("Invalid email or password");
        } else {
          setError("Something went wrong, please try again");
        }
      } finally {
        setLoading(false);
      }
    },
    [email, password, onLogin]
  );

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box p={2}>
        <Box textAlign="center">
          <Heading>Login</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <form onSubmit={onSubmit}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="test@example.com"
                value={email}
                onChange={onEmailChange}
                required
              />
            </FormControl>
            <FormControl mt={6}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="*******"
                value={password}
                onChange={onPasswordChange}
                required
              />
            </FormControl>
            {error ? (
              <Box my={4}>
                <Alert status="error" borderRadius={4}>
                  <AlertIcon />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              </Box>
            ) : null}
            <Button
              width="full"
              mt={4}
              type="submit"
              disabled={loading}
              isLoading={loading}
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};
