import { Box } from "@chakra-ui/react";
import { config } from "config";
import logo from "../assets/LogoCRF.png";

export const EnvHeader = () => (
  <Box>
    {config.environment.ENVIRONMENT === "development" && (
      <Box p={1} bgColor="blue.300" color="blue.700" textAlign="center">
        Development
      </Box>
    )}
    {config.environment.ENVIRONMENT === "staging" && (
      <Box p={1} bgColor="orange.300" color="orange.700" textAlign="center">
        Staging
      </Box>
    )}
    {config.environment.ENVIRONMENT === "production" && (
      <Box pl={5} pb={2} pt={2} bgColor="blue.200" textAlign="center">
        <img src={logo} width={200} />
      </Box>
    )}
  </Box>
);
