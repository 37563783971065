/**
 * input:
 * products.0.pvc must be a positive number
 * output:
 * Error in products CSV at line 2: pvc must be a positive number
 */
const beautifyClassValidatorError = (error: string) => {
  const [csv, index, ...rest] = error.split(".");

  const csvName = csv === "products" ? "products" : "categories";
  // +1 because excel rows start at 1.
  // +1 to account for the column titles.
  const lineNumber = parseInt(index) + 2;

  return `Error in ${csvName} CSV at line ${lineNumber}: ${rest.join(" ")}`;
};

export const beautifyErrors = (errors: string[]) => {
  const userFriendlyErrors = [];
  for (const error of errors) {
    if (
      error.startsWith("products.") ||
      error.startsWith("categories.") ||
      error.startsWith("subcategories.")
    ) {
      userFriendlyErrors.push(beautifyClassValidatorError(error));
      continue;
    }

    userFriendlyErrors.push(error);
  }

  return userFriendlyErrors;
};
