import { developmentConfig } from "./development";
import { productionConfig } from "./production";
import { stagingConfig } from "./staging";
import { Config } from "./types";

const getConfig = (): Config => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      return productionConfig;
    case "staging":
      return stagingConfig;
    case "development":
    default:
      return developmentConfig;
  }
};
export const config = getConfig();
