import { useState } from "react";
import { CatalogUpload } from "./CatalogUpload";
import { Login } from "./Login";

import { EnvHeader } from "../../components/EnvHeader";

function CarrefourSprint() {
  const [token, setToken] = useState("");

  return (
    <div className="App">
      <EnvHeader />
      {!!token ? <CatalogUpload token={token} /> : <Login onLogin={setToken} />}
    </div>
  );
}

export default CarrefourSprint;
